<template>
  <div class="page page-box page-padding">
    <!-- 页头 -->
    <header class="page-header">
      <el-form :inline="true" :model="listQuery" class="demo-form-inline ">
        <el-form-item label="课程模块：">
          <el-input v-model="listQuery.Name" placeholder="请输入训练模块名称" class="input-width" />
        </el-form-item>
        <el-form-item class="formButton">
          <el-button v-preventReClick @click="handleSearch" class="discoloration">查询</el-button>
          <el-button class="plain-button" v-preventReClick @click="resetSearch">重置</el-button>
          <el-button @click="addOrEidtClubTrainMod()" icon="el-icon-plus">
            <!-- <div class="img-center"> <img src="@/assets/add.png" alt="添加" class="add-img"> <span> 添加课程模块</span></div> -->
            添加课程模块
          </el-button>
        </el-form-item>
      </el-form>
    </header>
    <!-- 主体 -->
    <main class="page-main-main" ref="main">
      <!-- 列表渲染 -->
      <el-table v-loading="loading" :data="tableList" border fit stripe>
        <el-table-column prop="Name" label="课程模块" align="center" :show-overflow-tooltip="true" />
        <el-table-column prop="Duration" label="建议时长" align="center" width="80px">
          <template slot-scope="{ row }">
            {{  row.Duration }}分钟
          </template>
        </el-table-column>
        <el-table-column prop="Content" label="模块内容" align="center">
          <template slot-scope="{row}">
            <el-tooltip effect="dark" placement="top">
              <div v-html="row.Content" slot="content"></div>
              <div class="oneLine">{{row.Content}}</div>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column prop="Remarks" label="备注" align="center">
          <template slot-scope="{row}">
            <el-tooltip effect="dark" placement="top">
              <div v-html="row.Remarks" slot="content"></div>
              <div class="oneLine">{{row.Remarks}}</div>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center" width="100px">
          <template slot-scope="{ row }">
            <el-button type="text" @click="addOrEidtClubTrainMod(row)"> 编辑 </el-button>
            <el-button type="text" @click="deleteClubTrainMod(row)"> 删除 </el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <footer>
        <pagination v-show="total > 0" :total="total" :page.sync="listQuery.PageNo" :limit.sync="listQuery.PageSize" @pagination="getClubTrainModList" />
      </footer>
    </main>
    <!-- 新建编辑训练模块 -->
    <el-dialog :title="ClubTrainModTitle" :visible.sync="ClubTrainModVisible" width="40%" :close-on-click-modal='false'>
      <el-form :model="ClubTrainModForm" :rules="rules" ref='ClubTrainModForm' label-width="100px">
        <el-row>
          <el-col :span="11" class="newCol">
            <el-form-item label="课程模块:" prop="Name">
              <el-input v-model="ClubTrainModForm.Name" placeholder="请输入课程模块名称" show-word-limit maxlength="20" />
            </el-form-item>
          </el-col>
          <el-col :span="10" class="newCol">
            <el-form-item label="建议时长:" prop="Duration">
              <el-input type="number" v-model="ClubTrainModForm.Duration" placeholder="请输入时长" class="no-number" />
            </el-form-item>
          </el-col>
          <el-col :span="3" class="newCol unit">
            <span>分钟</span>
          </el-col>
        </el-row>
        <el-form-item label="模块内容:" prop="Content">
          <el-input v-model="ClubTrainModForm.Content" :autosize="{ minRows: 6, maxRows: 5 }" type="textarea" placeholder="请输入模块内容" show-word-limit maxlength="500" />
        </el-form-item>
        <br>
        <el-form-item label="备注:" prop="Remarks">
          <el-input v-model="ClubTrainModForm.Remarks" :autosize="{ minRows: 3, maxRows: 5 }" type="textarea" placeholder="请输入备注信息" show-word-limit maxlength="200" />
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="ClubTrainModVisible = false" class="plain-button">取 消</el-button>
        <el-button v-preventReClick @click="addOrUpdateClubTrainMod()" class="discoloration">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { getClubTrainModList, addOrUpdateClubTrainMod, delClubTrainMod } from "@/service/teaching";
import pagination from '@/components/pagination/pagination.vue';  // 引入分页
import { deepClone } from '@/utils/index';  // 引入深拷贝

const ClubTrainModForm = {  // 新增编辑训练模块的初始化数据
  Name: '',
  Duration: '',
  Content: '',
  Remarks: '',
};
const ListQuery = { // 查询数据
  PageNo: 1,
  PageSize: 50,
  Name: '',
};
export default {
  name: "Training",
  components: {
    pagination,
  },
  data() {

    const validateName = (rule, value, callback) => {
      if (!value || value.trim() === '') {
        return callback(new Error('请输入课程模块'));
      }
      callback();
    };

    const validateDuration = (rule, value, callback) => {
      this.ClubTrainModForm.Duration = parseInt(this.ClubTrainModForm.Duration)
      if (this.ClubTrainModForm.Duration === 0 || this.ClubTrainModForm.Duration > 0) {
        return callback();
      }
      callback(new Error('建议课时应该大于0'));
    };

    const validateContent = (rule, value, callback) => {
      if (!value || value.trim() === '') {
        return callback(new Error('请输入模块内容'));
      }
      callback();
    };

    return {
      formInline: { // 状态
        region: ''
      },
      ClubTrainModForm: deepClone(ClubTrainModForm),
      listQuery: deepClone(ListQuery),
      tableList: [],  // 表格数据
      total: 0, // 表格总条数
      loading: false, // 加载状态
      ClubTrainModTitle: '新增课程模块',  // 新建编辑title
      ClubTrainModVisible: false, // 新建编辑训练模块弹窗状态
      rules: {
        Name: [{ required: true, validator: validateName, trigger: 'blur' }],
        Duration: [{ required: true, validator: validateDuration, trigger: 'blur' }],
        Content: [{ required: true, validator: validateContent, trigger: 'blur' }],
      },
    }
  },
  created() {
    this.getClubTrainModList();
  },
  methods: {
    /**
      * 获取训练模块列表数据
      */
    async getClubTrainModList(data) {
      if (data) {
        this.listQuery.PageNo = data.page;
        this.listQuery.PageSize = data.limit;
      }
      this.loading = true;
      let res = await getClubTrainModList(this.listQuery);
      this.loading = false;
      this.tableList = res.DataList;
      this.total = res.Total;
    },

    /**
     * 页头-查询
     */
    handleSearch() {
      this.listQuery.PageNo = 1;
      this.listQuery.Name = this.listQuery.Name && this.listQuery.Name.trim();
      this.getClubTrainModList();
    },

    /**
     * 页头-重置
     */
    resetSearch() {
      this.listQuery = deepClone(ListQuery);
      this.getClubTrainModList();
    },

    /**
      * 操作/主要-新增或者编辑
      */
    addOrEidtClubTrainMod(row) {
      this.ClubTrainModVisible = true;
      if (row) {
        this.ClubTrainModForm = { ...row };
        this.ClubTrainModTitle = '编辑课程模块';
      } else {
        this.ClubTrainModForm = deepClone(ClubTrainModForm);
        this.ClubTrainModTitle = '添加课程模块';
      }
      this.$nextTick(() => {
        this.$refs.ClubTrainModForm.clearValidate();
      })

    },

    /**
     * 确定新增或者编辑
     */
    addOrUpdateClubTrainMod() {
      this.$refs.ClubTrainModForm.validate(async (valid) => {
        if (!valid) return;

        if (this.ClubTrainModTitle === '编辑训练模块') {
          await addOrUpdateClubTrainMod({
            Id: this.ClubTrainModForm.Id,
            Duration: this.ClubTrainModForm.Duration,
            Content: this.ClubTrainModForm.Content,
            Name: this.ClubTrainModForm.Name,
            Remarks: this.ClubTrainModForm.Remarks,
          });
          this.$message.success('编辑成功');
        } else {
          await addOrUpdateClubTrainMod(this.ClubTrainModForm);
          this.$message.success('新建成功');
        }

        this.ClubTrainModVisible = false;
        this.getClubTrainModList();
      })

    },

    /**
     * 操作-删除
     */
    async deleteClubTrainMod(row) {
      try {
        let res = await this.$confirm('确定要删除吗？', '警告', {
          distinguishCancelAndClose: true,
          confirmButtonText: '删 除',
          cancelButtonText: '取 消',
          type: 'warning',
          center: true,

        })
        if (res !== 'confirm') return;
        await delClubTrainMod({ Id: row.Id });
        this.getClubTrainModList();
        this.$message({
          message: '删除成功',
          type: 'success'
        });
      } catch (err) { };
    },
  },
}
</script>
<style lang='scss' scoped>
.newCol {
  padding: 0 !important;
}
.unit {
  line-height: 36px;
  text-align: center;
}
/deep/.el-table__body tr,
.el-table__body td {
  height: 54px !important;
}
</style>